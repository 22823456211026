<script>
import { inventoryIssueService } from "../../../helpers/backend/inventory_issue.service"
import { productService } from "../../../helpers/backend/product.service"
import { machineService } from "../../../helpers/backend/machine.service"
import { warehouseService } from "../../../helpers/backend/warehouse.service"
import { vehicleService } from "../../../helpers/backend/vehicle.service"

export default {
  data() {
    return {
      form: {
        id: null,
        product_id: null,
        location_type: null,
        location_id: null,
        type: null,
        quantity: null,
        description: null,
        problem_tray_column: null,
        problem_tray_row: null
      },
      product_options: [{ text: "Select One", value: null }],
      machine_options: [{ text: "Select One", value: null }],
      warehouse_options: [{ text: "Select One", value: null }],
      vehicle_options: [{ text: "Select One", value: null }],
      issue_type_options: [
        { text: "Select One", value: null }, 
        { text: "Expired", value: "expired"},
        { text: "Discrepancy", value: "discrepancy"},
        { text: "Fault", value: "fault"},
        { text: "Missing", value: "missing"},
        { text: "Stuck", value: "stuck"},
        { text: "Others", value: "others"}
      ],
      busy: false,
      show: true,
      showDismissibleAlert: false,
      error: "",
      active: false,
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    isUpdateState() {
      return this.form.id != null
    },
  },
  methods: {
    onSubmit(event) {
      event.preventDefault()
      this.busy = true
      inventoryIssueService.createOrUpdate(this.form).then(
        (data) => {
          if (data != null) {
            this.reset()
            this.$emit("refresh")
            this.showDismissibleAlert = true
            this.error = ""
            this.setActive(false)
          }
          this.busy = false
        },
        (error) => {
          this.showDismissibleAlert = true
          this.error =
            "Failed to Insert/Update Inventory Issue. Error : " + error
          this.busy = false
        }
      )
    },
    reset() {
      // Reset our form values
      this.form.id = null
      this.form.product_id = null
      this.form.location_type = null
      this.form.location_id = null
      this.form.type = null
      this.form.quantity = null
      this.form.description = null
      this.form.problem_tray_column = null
      this.form.problem_tray_row = null
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.showDismissibleAlert = false
      this.error = ""
      this.$nextTick(() => {
        this.show = true
      })
    },
    onReset(event) {
      event.preventDefault()
      this.setActive(false)
      this.reset()
    },
    setActive(b, outside_triger) {
      this.active = b
      if (outside_triger) return
      this.$emit("active_change", b)
    },
    initUpdate(id) {
      this.setActive(true)

      this.$nextTick(() => {
        this.reset()
        this.$refs.product_id.$el.focus()
        this.busy = true
        inventoryIssueService.getById(id).then(
          (data) => {
            console.log(data)
            if (data.issue != null) {
              this.form.id = data.issue.id
              this.form.product_id = data.issue.inventory.product.id
              // this.form.location_id = data.issue.inventory.location.location_type
              this.form.location_type = (data.issue.inventory.location.machine.id != 0? "machine" : ( data.issue.inventory.location.warehouse_id != 0? "warehouse" : "vehicle" ) )
              this.form.location_id = (data.issue.inventory.location.machine.id != 0? data.issue.inventory.location.machine.id : ( data.issue.inventory.location.warehouse.id != 0? data.issue.inventory.location.warehouse.id : data.issue.inventory.location.vehicle.id ) )
              this.form.type = data.issue.type
              this.form.quantity = data.issue.quantity
              this.form.description = data.issue.description
              this.form.problem_tray_column = data.issue.problem_tray_column
              this.form.problem_tray_row = data.issue.problem_tray_row
            } else {
              this.showDismissibleAlert = true
              this.error =
                "Failed to get inventory issue to update. Error : inventory issue not found"
            }
            this.busy = false
          },
          (err) => {
            this.showDismissibleAlert = true
            this.error = "Failed to get inventory issue to update. Error : " + err
            this.busy = false
          }
        )
      })
    },
    invokeCreate() {
      this.setActive(true)
    },
    fetchData() {
      productService.getAllProduct().then(
        (data) => {
          this.product_options = this.product_options.concat(
            data.products.map(function (item) {
              return { text: item.name, value: item.id }
            })
          )
        },
        (err) => {
          this.showDismissibleAlert = true
          this.error = "Failed to get machine type. Error : " + err
        }
      )
      machineService.getAllMachine().then(
        (data) => {
          this.machine_options = this.machine_options.concat(
            data.machines.map(function (item) {
              return { text: item.name, value: item.id }
            })
          )
        },
        (err) => {
          this.showDismissibleAlert = true
          this.error = "Failed to get machine type. Error : " + err
        }
      )

      warehouseService.getAll().then(
        (data) => {
          this.warehouse_options = this.warehouse_options.concat(
            data.warehouses.map(function (item) {
              return { text: item.name, value: item.id }
            })
          )
        },
        (err) => {
          this.showDismissibleAlert = true
          this.error = "Failed to get machine type. Error : " + err
        }
      )

      vehicleService.getAll().then(
        (data) => {
          this.vehicle_options = this.vehicle_options.concat(
            data.vehicles.map(function (item) {
              return { text: item.name, value: item.id }
            })
          )
        },
        (err) => {
          this.showDismissibleAlert = true
          this.error = "Failed to get machine type. Error : " + err
        }
      )
    }
  },
}
</script>


<template>
  <div v-if="this.active">
    <b-alert
      :variant="error == '' ? 'success' : 'danger'"
      dismissible
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
    >
      <div v-if="error != ''">
        {{ error }}
      </div>
      <div v-else>Success perform Insert/Update action</div>
    </b-alert>
    <div class="card">
      <div class="card-body">
        <div>
          <b-form @submit="onSubmit" @reset="onReset" v-if="show" ref="form">
            <b-overlay :show="busy" rounded="lg" opacity="0.6">
              <b-form-group
                id="input-group-product"
                label="Product :"
                label-for="input-product"
              >
                <b-form-select
                  id="input-product"
                  v-model="form.product_id"
                  :options="product_options"
                  ref="product_id"
                  required
                />
              </b-form-group>
              <b-row>
                <b-col>
                  <b-form-group
                    id="input-group-location-type"
                    label="Location :"
                    label-for="input-location-type"
                  >
                    <b-form-select
                      id="input-location-type"
                      v-model="form.location_type"
                      required
                    >
                      <b-form-select-option
                        :value="null"
                        label="Select Location"
                      />
                      <b-form-select-option value="machine" label="Machine" />
                      <b-form-select-option
                        value="warehouse"
                        label="Warehouse"
                      />
                      <!-- <b-form-select-option value="vehicle" label="Vehicle" /> -->
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    id="input-group-location-id"
                    label="."
                    label-for="input-location-id"
                  >
                    <b-form-select
                      id="input-product"
                      v-model="form.location_id"
                      :options="
                        form.location_type == 'machine'
                          ? machine_options
                          : form.location_type == 'warehouse'
                          ? warehouse_options
                          : vehicle_options
                      "
                      required
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group
                    id="input-group-type"
                    label="Type :"
                    label-for="input-type"
                  >
                    <b-form-select id="input-type" v-model="form.type" :options="issue_type_options" required>
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    id="input-group-quantity"
                    label="Quantity :"
                    label-for="input-quantity"
                  >
                    <b-form-input
                      id="input-quantity"
                      v-model="form.quantity"
                      type="number"
                      placeholder="Issue Quantity"
                      required
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <b-form-group
                    id="input-group-type"
                    label="Problem Tray Column :"
                    label-for="input-type"
                  >
                    <b-form-input
                      id="input-problem-tray-column"
                      v-model="form.problem_tray_column"
                      type="text"
                      placeholder="Problem Tray Column"
                    />
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    id="input-group-type"
                    label="Problem Tray Row :"
                    label-for="input-type"
                  >
                    <b-form-input
                      id="input-problem-tray-row"
                      v-model="form.problem_tray_row"
                      type="number"
                      placeholder="Problem Tray Row"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-button class="mr-2" type="submit" variant="primary">
                {{ isUpdateState ? "Update" : "Submit" }}
              </b-button>
              <b-button type="reset" variant="danger">Cancel</b-button>
            </b-overlay>
          </b-form>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <b-form-group>
      <b-button variant="primary" @click="invokeCreate">Create new</b-button>
    </b-form-group>
  </div>
</template>



