import { authHeader } from './auth-header'
import axiosInstance from "./client"

export const inventoryIssueService = {
    getAll,
    createOrUpdate,
    getById,
    deleteById,
}

function getById(id) {
    const requestOptions = {
        url: '/api/v1/user/inventory/issue/' + id,
        method: 'GET',
        headers: authHeader()
    }
    return axiosInstance(requestOptions)
}

function getAll() {
    // console.log(process.env)
    const requestOptions = {
        url: '/api/v1/user/inventory/issue',
        method: 'GET',
        headers: authHeader()
    }
    return axiosInstance(requestOptions)
}


function createOrUpdate(jsonData) {

    let formData = new FormData();
    // jsonData['ID'] = jsonData['id']
    for ( var key in jsonData ) {
        if (jsonData[key] != null)
            formData.append(key, jsonData[key])
    }
    let rMethod = jsonData["id"] != null ? "PUT" : "POST"
    const requestOptions = {
        url: '/api/v1/user/inventory/issue',
        method: rMethod,
        data: formData,
        headers: authHeader()
    }

    return axiosInstance(requestOptions)
}


function deleteById(id) {
    const requestOptions = {
        url: `/api/v1/user/inventory/issue/` + id,
        method: 'DELETE',
        headers: authHeader()
    }
    return axiosInstance(requestOptions)
}

